import axios from '@/config/axios'

// 设备维修列表接口
export const getDeviceRepairList = (params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/per/list`,
  params
})


// 设备维修列表接口
export const getDeviceRepairFinishList = (params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/finish/list`,
  params
})


// 设备维修列表接口
export const getDeviceRepairCommitList = (params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/commit/list`,
  params
})

// 设备维修列表接口
export const deviceRepairReceive = (orderId) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/receive/${orderId}`,
})



// 设备维修详情
export const getDeviceRepairDetail = (id) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/detail/${id}`
})

// 准备发起维修工单接口
export const getPreInitiate = (params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/preInitiate`,
  params
})

// 故障工单添加时校验是否有故障未处理
export const checkFault = (deviceUniqueNo) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/checkFault/${deviceUniqueNo}`,
})

// 发起维修工单接口
export const postInitiate = (data) => axios({
  method: 'post',
  url: `/mobile/deviceRepairOrder/initiate`,
  data
})

// 发起维修工单接口
export const finishOrder = (orderId, params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/finish/${orderId}`,
  params
})

// 验收维修工单接口
export const postRepairApproval = (repairId, approvalStatus) => axios({
  method: 'post',
  url: `/mobile/deviceRepairOrder/approval/${repairId}/${approvalStatus}`
})

// 撤销维修工单接口
export const postRepairCancel = (repairId) => axios({
  method: 'post',
  url: `/mobile/deviceRepairOrder/cancel/${repairId}`
})
