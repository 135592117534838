<template>
  <div class="executed-list-wrapper">
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
    
      <div class="executed-item-box">
        <div class="bottom">
          <ul>
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="handleRepairStatusClick(item)"
            >
              <div class="left">
                <i class="iconfont icondianjianguanli"></i>
                <span>{{ item.deviceName }}</span>
              </div>
              <div class="right">
                <span>{{ item.faultStatus }}</span>
                <i
                  class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
                ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </van-list> -->
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="executed-item-box" v-for="(item, index) in list" :key="index" @click="handleRepairStatusClick(item)">
        <div class="item-box-info">
          <div class="info-left">
            <i class="iconfont icondianjianguanli"></i>
          </div>
          <div class="info-center">
            <span class="title">{{item.deviceName}}</span>
            <div class="detail">
              <span>设备编号：{{item.deviceNo}}</span>
              <span>发生时间：{{item.commitTime}}</span>
              <span class="ellipsis">故障内容：{{item.faultReason}}</span>
              <span class="ellipsis">接单人：{{item.receiveUserName}}</span>
            </div>
          </div>
          <div class="info-right">
            <!-- <span>{{ item.faultStatus }}</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i> -->
            <span :style="{'color':filterColor(item.faultStatus)  }">{{item.faultStatusName}}</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
          </div>
        </div>

      </div>
    </van-list>

    <!-- <van-dialog v-model="show" title="维修对象" class="repair-dialog-way" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick" show-cancel-button>
      <div class="repair-dialog-info f-row">
        <span class="info-key">设备：</span><span class="info-value">{{
          dialogDetail && dialogDetail.deviceName
        }}</span>
      </div>
      <div class="repair-dialog-info f-row">
        <span class="info-key">编号：</span><span class="info-value">{{
          dialogDetail && dialogDetail.repairNo
        }}</span>
      </div>
    </van-dialog> -->
    <Dialog :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick">
      <div class="repair-dialog-info f-row" >
        <span class="info-key">设备：</span><span class="info-value">{{
          dialogDetail && dialogDetail.deviceName
        }}</span>
      </div>
      <div class="repair-dialog-info f-row" >
        <span class="info-key">编号：</span><span class="info-value">{{
          dialogDetail && dialogDetail.repairNo
        }}</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import Dialog from '@/components/Dialog'
import { List, RadioGroup, Radio, Notify } from 'vant'
import 'vant/lib/notify/style'

import {
  getDeviceRepairList,
  getPreInitiate,
  deviceRepairReceive,
} from '@/api/deviceRepair'
Vue.use(List, RadioGroup, Radio, Notify)
export default {
  name: 'ExecutedList',
  components: {
    Dialog,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      dialogInfo: {
        title: '维修对象',
        cancerTxt: '取消',
        queryTxt: '确定接单',
      },
      params: {
        page: 0,
        size: 10,
      },
      currentTaskId: 0,
      dialogDetail: null,
    }
  },
  methods: {
    /**
     * 列表
     */
    getDeviceRepairList() {
      if (this.params.page === 0) {
        this.list = []
      }
      getDeviceRepairList(this.params)
        .then((res) => {
          const content = res.content
          let arr = []
          content.forEach((item) => {
            // switch (item.faultStatus) {
            //   case -1:
            //     item.faultStatus = '作废 '
            //     break
            //   case 0:
            //     item.faultStatus = '待派单'
            //     break
            //   case 1:
            //     item.faultStatus = '已派单'
            //     break
            //   case 2:
            //     item.faultStatus = '已接单'
            //     break
            //   case 3:
            //     item.faultStatus = '已完成'
            //     break
            //   case 4:
            //     item.faultStatus = '验收通过'
            //     break
            //   case 5:
            //     item.faultStatus = '验收不通过'
            //     break
            // }
            arr.push(item)
          })
          this.list = arr
          if (this.list.length >= res.totalElements) {
            this.finished = true
          }
          this.params.page = this.params.page + 1
        })
        .catch((err) => {
          this.finished = true
        })
    },
    /**
     * 进入点检任务线路前确认接口
     */
    // getPreCheckStatus(id) {
    //   this.currentTaskId = id
    //   getPreCheckStatus(id).then((res) => {
    //     if (res.retCode === 0) {
    //       //异常
    //       this.show1 = true
    //       this.dialogInfo1.question = res.retMsg
    //     }
    //     if (res.retCode === 1) {
    //       //正常
    //       this.show = true
    //       this.dialogInfo.question = res.retMsg
    //     }
    //   })
    // },
    handleCloseDialogClick() {
      this.show = false
    },
    /**
     * 维修状态
     */
    handleRepairStatusClick(item) {
      this.dialogDetail = item

      if (item.faultStatus == '已派单' || item.faultStatus == '1') {
        this.show = true
      } else {
        this.$router.push({
          path: '/deviceRepair/info',
          query: {
            deviceNo: item.id,
            fault: true,
            maintain: true,
            faultCanEidt: false,
          },
        })
      }
    },
    handleQueryDialogClick() {
      let id = this.dialogDetail.id
      this.show = false
      deviceRepairReceive(id).then((res) => {
        if (res.retCode == '1') {
          Notify({ type: 'success', message: res.retMsg })
          this.$router.push({
            path: '/deviceRepair/info',
            query: {
              deviceNo: id,
              fault: true,
              maintain: true,
              faultCanEidt: false,
            },
          })
        }
      })
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getDeviceRepairList()
        this.loading = true
      }, 500)
    },
    filterColor(val) {
      var statusList = {
       0: '#E6A23C',
        1: '#4A8CFF',
        2: '#4A8CFF',
        3: '#67C23A',
        4: '#67C23A',
        5: '#F56C6C',
        '-1': '#CCCCCC',
      }
      return statusList[val]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.executed-list-wrapper {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .executed-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    .item-box-title {
      padding: 0.3rem 0.5rem;
      span {
        color: #999999;
        font-size: 0.7rem;
        font-weight: bold;
      }
      border-bottom: 1px solid #ebedf0;
    }
    .item-box-info {
      padding: 0.3rem 0rem;
      display: flex;
      flex-direction: row;
      align-items: top;
      .info-left {
        margin: 0 0.55rem;
        i {
          color: #409eff;
          font-size: 1rem;
        }
      }
      .info-center {
        width: 0;
        flex: 1;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        .title {
          display: block;
          color: #333333;
          font-size: 0.68rem;
          font-weight: bold;
          margin: 0.4rem 0;
        }
        .detail {
          margin: 0.2rem 0;
          span {
            color: #666;
            font-size: 0.5rem;
            display: block;
            margin: 0.3rem 0;
            font-weight: bold;
          }
        }
      }
      .info-right {
        padding: 0 0.5rem;
        span {
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
    }
  }
  .van-overlay{
    .repair-dialog-info{
      display: none;
    }
  }
  .block {
    .repair-dialog-info {
      display: flex;
      margin: 0.5rem 0;
      .info-key {
        color: #333;
        font-size: 0.7rem;
      }
      .info-value {
        width: 0;
        flex: 1;
        color: #999999;
        font-size: 0.7rem;
        word-wrap: break-word;
      }
    }
    .repair-dialog-way {
      /deep/ .van-radio-group {
        margin-top: 1rem;
        .van-radio {
          text-align: center;
          margin: 0.3rem auto;
          .van-radio__icon {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }
          .van-radio__label {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
